<template>
  <transition name="slide-fade">
    <div
      class="header-menu"
      :class="{ 'is-opened': isOpenMenu, knives: $route.name === 'knives' }"
      v-if="isOpenMenu"
    >
      <div class="container">
        <div class="header-menu__content">
          <div class="header-menu__left">
            <div ref="headerLogo" class="header-menu__logo">
              <button
                class="burger"
                @click="openMenu"
                :class="{ 'is-active': isOpenMenu }"
              >
                <span class="burger-line burger-top"></span>
                <span class="burger-line burger-middle"></span>
                <span class="burger-line burger-bottom"></span>
              </button>
              <locale-router-link to="" class="header-menu__logo-box">
                <div v-if="$route.name === 'knives'">
                  <img
                    v-if="$i18n.locale === 'ru'"
                    src="@/assets/img/icons/logo-footer-knives.svg"
                    alt="Logo Magnum"
                    class="header-menu-logo-desktop"
                  />
                  <img
                    v-else
                    src="@/assets/img/icons/logo-footer-knives-kz.svg"
                    alt="Logo Magnum"
                    class="header-menu-logo-desktop"
                  />
                </div>
                <div v-else>
                  <img
                    v-if="$i18n.locale === 'ru'"
                    src="@/assets/img/icons/logo-footer.svg"
                    alt="logo"
                    class="header-menu-logo-desktop"
                  />
                  <img
                    v-else
                    src="@/assets/img/icons/logo-footer-kz.svg"
                    alt="Logo Magnum"
                    class="header-menu-logo-desktop"
                  />
                </div>

                <img
                  src="@/assets/img/icons/logo-mobile.svg"
                  alt="logo"
                  class="header-menu-logo-mobile"
                />
                <p class="header-menu__title-mobile">
                  {{ $t("WeFillLife") }}
                </p>
              </locale-router-link>
            </div>
            <div class="header-menu__list">
              <locale-router-link to="stocks" class="header-menu__link">
                {{ $t("stock") }}</locale-router-link
              >
              <locale-router-link to="catalog" class="header-menu__link">{{
                $t("discountCatalog")
              }}</locale-router-link>
              <locale-router-link to="magnumclub" class="header-menu__link">{{
                $t("loyaltyProgram")
              }}</locale-router-link>
              <locale-router-link to="cooking" class="header-menu__link">
                {{ $t("cookingWithMagnum") }}
              </locale-router-link>
              <locale-router-link to="magnumgo" class="header-menu__link">
                {{ $t("orderWithDelivery") }}</locale-router-link
              >
              <locale-router-link to="shops" class="header-menu__link">
                {{ $t("shops") }}</locale-router-link
              >
              <a
                href="https://corp.magnum.kz/ru"
                target="_blank"
                class="header-menu__link"
                >Magnum Corp.</a
              >
              <locale-router-link to="contacts" class="header-menu__link">
                {{ $t("contacts") }}</locale-router-link
              >
            </div>
          </div>
          <div class="header-menu__right">
            <div class="header-menu__right-inner">
              <locale-router-link to="about" class="header-menu__link">{{
                $t("aboutCompany")
              }}</locale-router-link>
              <locale-router-link to="career" class="header-menu__link">{{
                $t("workingMagnum")
              }}</locale-router-link>
              <locale-router-link to="news" class="header-menu__link">{{
                $t("companyNews")
              }}</locale-router-link>
              <!-- <locale-router-link to="gsp" class="header-menu__link">{{
                $t("GSP")
              }}</locale-router-link> -->
              <a
                href="https://corp.magnum.kz/ru/compliance-service?city_id=2"
                class="header-menu__link"
                >{{ $t("compliance") }}</a
              >
              <locale-router-link to="interview" class="header-menu__link">{{
                $t("pollByAssortment")
              }}</locale-router-link>
            </div>
          </div>
          <div class="header-menu__settings">
            <div
              v-if="selectedCity"
              class="header-menu__address"
              @click="openSelectCity"
            >
              {{ selectedCity.attributes.name.substring(0, 3) }}
            </div>
            <lang-change />
          </div>
        </div>
        <div class="header-menu__box header-menu-bottom">
          <div class="header-menu-bottom__item">
            <div class="header-menu__phones">
              <span>
                {{ $t("support") }}
              </span>
              <a href="tel:7766"
                >7766 <span class="dash">|</span>
                {{ $t("contactCenterPhone_4") }}</a
              >
            </div>
            <div class="header-menu__social-links">
              <span>
                {{ $t("weAreInSocialNetworks") }}
              </span>
              <div class="header-menu__links">
                <a
                  v-if="social.attributes.facebook_active"
                  class="fb"
                  :href="social.attributes.facebook_link"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.IG_active"
                  class="insta"
                  :href="social.attributes.IG"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.twitter_active"
                  class="twitter"
                  :href="social.attributes.twitter"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.tiktok_active"
                  class="tiktok"
                  :href="social.attributes.tiktok"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.telegram_active"
                  class="telegram"
                  :href="social.attributes.telegram"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.vk_active"
                  class="vk"
                  :href="social.attributes.vk"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.ok_active"
                  class="ok"
                  :href="social.attributes.ok"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
          <div class="header-menu-bottom__item">
            <!-- Служба доставки Magnum GO -->
            <div class="contacts__item">
              <h3 class="contacts__item-title">
                {{ $t("deliveryServiceTitle") }}
              </h3>
              <a :href="`tel:${tel}`" class="contacts__phone">
                <span>{{ tel }}</span
                ><span class="dash">|</span>
                <span>{{ $t("contactCenterPhone_4") }}</span>
              </a>
              <!-- <a
                href="https://t.me/MagnumGo_bot"
                target="_blank"
                class="telegram-link"
              >
                <img
                  src="@/assets/img/icons/magnum-go-logo.svg"
                  alt="magnum go logo"
                  width="37"
                  height="37"
                />
                <span class="color-pink"> @MagnumGO_bot</span>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { getSocialLink } from "@/api/links";
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
export default {
  name: "AppMenu",
  data() {
    return {
      social: null,
      tel: "7772",
    };
  },
  components: {
    LangChange: () => import("@/components/language/LangChange.vue"),
  },
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
  },
  methods: {
    ...mapActions({
      closeMenu: actionTypes.changeIsOpenMenu,
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
    }),
  },
  watch: {
    "$route.path"() {
      if (this.isOpenMenu) {
        this.closeMenu();
      }
    },
  },
  created() {
    getSocialLink().then((social) => {
      this.social = social;
    });
  },
  mounted() {
    // let date = new Date().toLocaleString();
    // let firstDate = (date.split(":")[0] + ":" + date.split(":")[1])
    //   .split(",")
    //   .join("");
    // let secondDate = "20.04.2023 00:00";
    // let datetime_regex = /(\d\d)\.(\d\d)\.(\d\d\d\d)\s(\d\d):(\d\d)/;
    // let first_date_arr = datetime_regex.exec(firstDate);
    // let first_datetime = new Date(
    //   first_date_arr[3],
    //   first_date_arr[2],
    //   first_date_arr[1],
    //   first_date_arr[4],
    //   first_date_arr[5]
    // );
    // let second_date_arr = datetime_regex.exec(secondDate);
    // let second_datetime = new Date(
    //   second_date_arr[3],
    //   second_date_arr[2],
    //   second_date_arr[1],
    //   second_date_arr[4],
    //   second_date_arr[5]
    // );
    // console.log(first_datetime.getTime() >= second_datetime.getTime());
    // if (first_datetime.getTime() >= second_datetime.getTime()) {
    //   this.tel = "7777";
    // } else {
    //   this.tel = "7772";
    // }
  },
};
</script>

<style lang="scss" scoped>
.app {
  display: inline-block;
  margin-top: 20px;
  padding: 20px 40px;
  background-color: red;
  color: #fff;
  border-radius: 20px;
}
</style>
